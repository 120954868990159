// React
import React, { useEffect } from 'react';

// Gatsby
import { graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import { StickyContainer } from 'react-sticky';

// Components
import SEO from 'components/seo';
import ProductHero from 'components/_product/productHero';
import ProductDescription from 'components/_product/productDescription';
import ProductBrewGuide from 'components/_product/productBrewGuide';
import RelatedProducts from 'components/_shop/relatedProducts';
import RelatedStories from 'components/_stories/relatedStories';
import ImageSlider from 'components/imageSlider';
import Subscription from 'components/subscription';
import Newsletter from 'components/newsletter';

const CourseComponent = props => {
    const {
        location,
        pageContext,
        pageContext: { locale, labels },
        data: {
            datoCmsCourse,
            datoCmsCourse: {
                gallery = [],
                seoMetaTags,
                showSubscription = false,
                showNewsletter = false,
                showBrewGuides = false,
                relatedProducts = [],
                relatedBlogPost = [],
            },
            datoCmsPageCourse,
        },
    } = props;

    // Set theme
    const [, setPageTheme] = useGlobal('pageTheme');
    const [heroTheme, setHeaderTheme] = useGlobal('headerTheme');
    useEffect(() => {
        setPageTheme(s.themeColor({ color: 'categories', index: 1 }));
        setHeaderTheme(s.themeColor({ color: 'categories', index: 2 }));
    }, []);

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <StickyContainer>
                    <ContentWrapper>
                        <ProductHero
                            {...{
                                parent: {
                                    ...datoCmsPageCourse,
                                    theme: { color: 'categories', index: 2 },
                                },
                                theme: heroTheme,
                                backgroundImage: datoCmsCourse.image,
                                product: datoCmsCourse,
                                productVariations: [
                                    {
                                        language: 'DA',
                                        label: labels.PAGES.PRODUCT[
                                            'danish'.toUpperCase()
                                        ],
                                    },
                                    {
                                        language: 'EN',
                                        label: labels.PAGES.PRODUCT[
                                            'english'.toUpperCase()
                                        ],
                                    },
                                ],
                                location,
                                pageContext,
                            }}
                        />

                        <ProductDescription
                            {...{
                                product: datoCmsCourse,
                                pageContext,
                            }}
                        />
                        {gallery.length > 0 && (
                            <ImageSlider
                                {...{
                                    theme: s.color('white'),
                                    images: gallery,
                                }}
                            />
                        )}
                        {showBrewGuides && (
                            <ProductBrewGuide {...{ location, pageContext }} />
                        )}
                        {relatedProducts.length > 0 && (
                            <RelatedProducts
                                {...{
                                    pageContext,
                                    location,
                                    products: relatedProducts,
                                }}
                            />
                        )}
                        {relatedBlogPost.length > 0 && (
                            <RelatedStories
                                {...{
                                    pageContext,
                                    location,
                                    stories: relatedBlogPost,
                                }}
                            />
                        )}
                        {showNewsletter && (
                            <Newsletter
                                {...{
                                    pageContext,
                                    location,
                                }}
                            />
                        )}
                        {showSubscription && (
                            <Subscription
                                {...{
                                    pageContext,
                                    location,
                                }}
                            />
                        )}
                    </ContentWrapper>
                </StickyContainer>
            </s.layout.PageContainer>
        </>
    );
};

export default CourseComponent;

export const courseQuery = graphql`
    query CourseQuery($itemId: String!) {
        datoCmsCourse(id: { eq: $itemId }) {
            ...ProductCourseFragment
        }
        datoCmsPageCourse {
            slug
        }
    }
`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: ${s.layout.zIndex.content};
`;
